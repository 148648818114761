import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { combineLatest, EMPTY, race, Subject } from 'rxjs';
import { BreadcrumbService } from '../../../layout/breadcrumb/breadcrumb.service';
import { PageMetaService } from '../../../core/services/page-meta.service';
import { FlexModule } from 'ngx-flexible-layout';
import { DirectivesModule } from '../../../shared/directives/directives.module';
import { UiModule } from '../../../shared/ui/ui.module';
import { select, Store } from '@ngrx/store';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { inputIsNotUndefined } from '../../../data-access/input-is-not-null-or-undefined';
import { GetEquipmentModule } from "../../store/get-equipment/get-equipment.module";
import { getEquipmentAction } from "../../store/get-equipment/get-equipment.actions";
import { selectGetEquipment, selectGetEquipmentLoading } from "../../store/get-equipment/get-equipment.selectors";
import { AsyncPipe } from "@angular/common";
import { NgbNav, NgbNavItem, NgbNavLinkBase } from "@ng-bootstrap/ng-bootstrap";
import { GetExternalEquipmentModule } from "../../store/get-external-equipment/get-external-equipment.module";
import { getExternalEquipmentAction } from "../../store/get-external-equipment/get-external-equipment.actions";
import { selectGetExternalEquipment } from "../../store/get-external-equipment/get-external-equipment.selectors";

@Component({
  selector: 'app-equipment-details-layout',
  templateUrl: './equipment-details-layout.component.html',
  styleUrls: [ './equipment-details-layout.component.scss' ],
  standalone: true,
  imports: [
    FlexModule,
    DirectivesModule,
    UiModule,
    GetEquipmentModule,
    AsyncPipe,
    NgbNav,
    NgbNavLinkBase,
    NgbNavItem,
    RouterOutlet,
    RouterLinkActive,
    RouterLink,
    GetExternalEquipmentModule,
  ]
})
export class EquipmentDetailsLayoutComponent implements OnInit, OnDestroy {
  isLoading$ = this.store$.pipe(select(selectGetEquipmentLoading));
  scope$ = this.route.queryParamMap.pipe(
    map(queryParamMap => queryParamMap.get('scope')),
  ) || EMPTY;
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    public route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private pageMetaService: PageMetaService,
    private store$: Store,
  ) {
  }

  ngOnInit(): void {
    this.breadcrumbService.set(
      {
        name: 'Equipment',
        list: [
          {
            caption: 'Equipment',
            link: [ 'equipment' ],
            active: false,
          }
        ]
      }
    );

    const paramMap$ = this.route.paramMap;
    if (!paramMap$) throw new Error('Unexpected: Missed queryParams');

    const queryParamMap$ = this.route.queryParamMap;
    if (!queryParamMap$) throw new Error('Unexpected: Missed queryParamMap');

    combineLatest([
      paramMap$,
      queryParamMap$,
    ]).subscribe(([ paramMap, queryParamMap ]) => {
      const id = paramMap.get('id');
      if (!id) throw new Error('Unexpected: Missed equipment id');
      const scope = queryParamMap.get('scope');

      if (!scope) {
        this.store$.dispatch(getEquipmentAction({
          payload: {
            id,
          },
        }));
      } else if (scope === 'external') {
        this.store$.dispatch(getExternalEquipmentAction({
          payload: {
            id,
          },
        }));
      } else {
        throw new Error(`Unexpected: Missed handing of scope: ${ scope }`);
      }
    });

    race(
      this.store$.pipe(select(selectGetExternalEquipment), filter(inputIsNotUndefined)),
      this.store$.pipe(
        select(selectGetEquipment),
        filter(inputIsNotUndefined),
      )
    ).pipe(
      take(1),
      takeUntil(this.unsubscribe$),
    ).subscribe(equipment => {
      if ('_id' in equipment) {
        this.pageMetaService.patchMeta({ accountName: equipment.type.name });
        this.breadcrumbService.addToList(
          {
            caption: `${ equipment.type.name } - ${ equipment.serialNumber }`,
            link: [ 'equipment', equipment._id ],
            active: true,
          }
        );
      } else {
        this.pageMetaService.patchMeta({ accountName: equipment.name });
        this.breadcrumbService.addToList(
          {
            caption: `${ equipment.name } - ${ equipment.serialNumber }`,
            link: [ 'equipment', equipment.serialNumber ],
            active: true,
          }
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.breadcrumbService.clear();
    this.pageMetaService.clearMeta();
  }
}
