import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../../layout/breadcrumb/breadcrumb.service';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { inputIsNotNull, inputIsNotNullOrUndefined } from '../../data-access/input-is-not-null-or-undefined';
import { select, Store } from '@ngrx/store';
import { combineLatest, of, Subject } from 'rxjs';
import { updateAccountVisitsAction } from '../../user-history/update-account-visits/store/update-account-visits.actions';
import { DailyRouteUserIdService } from '../../core/services/daily-route-user-id.service';
import { selectCurrentUserId, selectCurrentUserType } from "../../current-user/store/current-user.selectors";
import { UserTypes } from "../../users/user.types";
import { selectGetRelatedUsers } from "../../users/get-related-users/store/get-related-users.selectors";
import { AccountVisibility, AccountVisibilityService, AccountVisibilityValue } from '../account-visibility.service';
import { PageMetaService } from '../../core/services/page-meta.service';
import { AccountScope } from "../../models/account";
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerConfigService, getDatepickerConfig } from '../../core/services/datepicker-config.service';
import { selectGetCompanyIndustry } from '../../company/get-company-users/store/get-company-users.selectors';
import { AccountDealsLayoutStyleService } from '../account-deals-layout-style.service';
import { getAccountV2Action } from "../get-account-v2/store/get-account-v2.actions";
import { selectGetAccountV2, selectGetAccountV2Loading } from "../get-account-v2/store/get-account-v2.selectors";
import { getAccountsV2ByIdsAction } from "../get-accounts-v2-by-ids/store/get-accounts-v2-by-ids.actions";
import { getAccountChildrenAction } from "../get-account-children/store/get-account-children.actions";

@Component({
  selector: 'app-account-details-layout',
  templateUrl: './account-details-layout.component.html',
  styleUrls: [ './account-details-layout.component.scss' ],
  providers: [
    {
      provide: NgbDatepickerConfig,
      useFactory: getDatepickerConfig,
      deps: [ DatepickerConfigService ],
    },
    AccountDealsLayoutStyleService,
  ],
})
export class AccountDetailsLayoutComponent implements OnInit, OnDestroy {
  currentUserType$ = this.store$.pipe(
    select(selectCurrentUserType),
    filter(inputIsNotNullOrUndefined),
  );
  isLoading$ = this.store$.pipe(select(selectGetAccountV2Loading));
  account$ = this.store$.pipe(select(selectGetAccountV2));
  allowToViewAccount$ = combineLatest([
    this.store$.pipe(select(selectCurrentUserId), filter(inputIsNotNullOrUndefined)),
    this.store$.pipe(select(selectCurrentUserType), filter(inputIsNotNullOrUndefined)),
    this.store$.pipe(select(selectGetRelatedUsers), filter(inputIsNotNullOrUndefined)),
    this.account$,
  ]).pipe(
    switchMap(([ currentUserId, currentUserType, relatedUsers, account ]) => {
      if ((account && currentUserType === UserTypes.OWNER)
        || [ UserTypes.LEAD, UserTypes.INSIDE_SALES ].includes(currentUserType) && relatedUsers.some(rUser =>
          !!account?.users.some(u => u.id === rUser.id)
        ) || !!account?.users.some(u => u.id === currentUserId)) {
        return of<AccountVisibility>({ visibility: AccountVisibilityValue.edit });
      } else {
        return of({ visibility: AccountVisibilityValue.view });
      }
    }),
  );
  industry$ = this.store$.pipe(select(selectGetCompanyIndustry));
  dealsLayoutStyle$ = this.accountDealsLayoutStyleService.styleClass$;
  private readonly unsubscribe$ = new Subject<void>();

  accountCaptions = AccountScope;

  constructor(
    public route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private store$: Store,
    private dailyRouteUserIdService: DailyRouteUserIdService,
    private accountVisibilityService: AccountVisibilityService,
    private pageMetaService: PageMetaService,
    private accountDealsLayoutStyleService: AccountDealsLayoutStyleService
  ) {
  }

  ngOnInit(): void {
    const params$ = this.route.params;
    if (!params$) throw new Error('Unexpected: Missed params');

    params$.pipe(
      map(({ accountId }: { accountId?: string }) => accountId),
      filter(inputIsNotNullOrUndefined),
      takeUntil(this.unsubscribe$),
    ).subscribe(accountId =>
      this.store$.dispatch(getAccountV2Action({ id: accountId }))
    );

    this.account$.pipe(
      filter(inputIsNotNullOrUndefined),
      takeUntil(this.unsubscribe$),
    ).subscribe(account => {
      this.pageMetaService.patchMeta({ accountName: account.name });
      this.breadcrumbService.set({
        name: 'accounts',
        list: [
          {
            caption: 'Accounts',
            link: [ '/accounts' ],
            active: false,
          },
          {
            caption: account.name,
            link: [],
            active: true,
          }
        ]
      });

      const accountId = this.route.snapshot.paramMap.get('accountId');
      if (!accountId) throw new Error('Missed account ID');

      this.store$.dispatch(updateAccountVisitsAction({ accountId }));
    });

    combineLatest([
      this.account$.pipe(filter(inputIsNotNull)),
      this.currentUserType$.pipe(filter(currentUserType => currentUserType !== UserTypes.OWNER)),
    ]).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(([ account ]) => {
      if (account.scope === AccountScope.child && account.parentAccounts) {
        this.store$.dispatch(getAccountsV2ByIdsAction({
          params: {
            ids: account.parentAccounts.map(a => a.id),
          },
        }));
      } else {
        this.store$.dispatch(getAccountChildrenAction({
          id: account.fbId,
        }));
      }
    });

    this.allowToViewAccount$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(visibility =>
        this.accountVisibilityService.set(visibility)
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.breadcrumbService.clear();
    this.dailyRouteUserIdService.clear();
    this.pageMetaService.clearMeta();
  }
}
