import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetLocalSettingsService } from '../get-local-settings.service';
import {
  getLocalSettingsAction,
  getLocalSettingsSuccessAction,
  getLocalSettingsFailureAction,
} from './get-local-settings.actions';

@Injectable()
export class GetLocalSettingsEffects {
  getLocalSettings$ = createEffect(() => this.actions$.pipe(
    ofType(getLocalSettingsAction),
    switchMap(() =>
      this.getLocalSettingsService.get().pipe(
        map(data => getLocalSettingsSuccessAction({ data })),
        catchError(error => of(getLocalSettingsFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getLocalSettingsService: GetLocalSettingsService,
  ) {
  }
}
