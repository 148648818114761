import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getExternalEquipmentAction, getExternalEquipmentFailureAction, getExternalEquipmentSuccessAction } from './get-external-equipment.actions';
import { GetExternalEquipmentService } from './get-external-equipment.service';

@Injectable()
export class GetExternalEquipmentEffects {
  getExternalEquipment$ = createEffect(() => this.actions$.pipe(
    ofType(getExternalEquipmentAction),
    switchMap(action =>
      this.getExternalEquipmentService.get(action.payload.id).pipe(
        map(data => getExternalEquipmentSuccessAction({ data })),
        catchError(error => of(getExternalEquipmentFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getExternalEquipmentService: GetExternalEquipmentService,
  ) {
  }
}
