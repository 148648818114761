import { createAction, props } from '@ngrx/store';
import { ActionTypes } from './action-types';
import { ExternalEquipmentSchema } from "../../../models/external-equipment";

export const getExternalEquipmentAction = createAction(
  ActionTypes.GET_EXTERNAL_EQUIPMENT,
  props<{ payload: { id: string } }>(),
);

export const getExternalEquipmentSuccessAction = createAction(
  ActionTypes.GET_EXTERNAL_EQUIPMENT_SUCCESS,
  props<{ data: ExternalEquipmentSchema }>()
);

export const getExternalEquipmentFailureAction = createAction(
  ActionTypes.GET_EXTERNAL_EQUIPMENT_FAILURE,
  props<{ error: unknown }>()
);
