import { Action, createReducer, on } from '@ngrx/store';
import {
  getExternalEquipmentAction, getExternalEquipmentSuccessAction, getExternalEquipmentFailureAction,
} from './get-external-equipment.actions';
import { GetExternalEquipmentState } from './get-external-equipment.types';

const initialState: GetExternalEquipmentState = {
  updating: false,
  updated: false,
};

const getExternalEquipmentReducer = createReducer<GetExternalEquipmentState>(
  initialState,
  on(
    getExternalEquipmentAction,
    state => ({
      ...state,
      updating: true,
      updated: false,
      data: undefined,
    })
  ),
  on(
    getExternalEquipmentSuccessAction,
    (state, action) => ({
      ...state,
      updating: false,
      updated: true,
      data: action.data,
      error: undefined,
    })
  ),
  on(
    getExternalEquipmentFailureAction,
    (state, action) => ({
      ...state,
      updating: false,
      updated: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: GetExternalEquipmentState, action: Action) => getExternalEquipmentReducer(state, action);
