import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './get-external-equipment.reducers';
import { GetExternalEquipmentEffects } from './get-external-equipment.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([ GetExternalEquipmentEffects ]),
    StoreModule.forFeature('getExternalEquipment', reducer),
  ],
})
export class GetExternalEquipmentModule {
}
