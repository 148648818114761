import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadCurrentUserAction, loadCurrentUserFailureAction, loadCurrentUserSuccessAction, } from './current-user.actions';
import { exhaustMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetCurrentUserService } from '../get-current-user.service';

@Injectable()
export class CurrentUserEffects {
  currentUser$ = createEffect(() => this.actions$.pipe(
    ofType(loadCurrentUserAction),
    exhaustMap(action =>
      this.getCurrentUserService.get(action.payload.uid).pipe(
        map(userAndPersonalInfo => loadCurrentUserSuccessAction({
          data: userAndPersonalInfo
        })),
        catchError(error => of(loadCurrentUserFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getCurrentUserService: GetCurrentUserService,
  ) {
  }
}
