import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { RedirectComponent } from './redirect.component';
import { PurchaseResolver } from './core/resolvers/purchase.resolver';
import { LayoutComponent } from './layout/layout.component';
import { AccountDetailsLayoutComponent } from './accounts/account-details-layout/account-details-layout.component';
import { CompanySettingsResolver } from './core/resolvers/company-settings.resolver';
import { RelatedUsersResolver } from './core/resolvers/related-users.resolver';

import { DealDetailsLayoutComponent } from './deals/deal-details/deal-details-layout/deal-details-layout.component';
import { PermissionsGuard } from "./core/permissions.guard";
import { EquipmentDetailsLayoutComponent } from "./equipment/equipment-details/equipment-details-layout/equipment-details-layout.component";

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo([ 'auth/sign-in' ]);
const redirectLoggedInToDashboard = () => redirectLoggedInTo([ 'dashboard' ]);

const routes: Routes = [
  {
    path: '',
    redirectTo: '/auth/sign-in',
    pathMatch: 'full',
  },
  {
    path: 'redirect',
    component: RedirectComponent,
  },
  {
    path: 'ui-style',
    loadChildren: () => import('./ui-style/ui-style.module').then(m => m.UiStyleModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [
      AuthGuard,
    ],
    data: {
      authGuardPipe: redirectLoggedInToDashboard,
    },
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [
      AuthGuard,
    ],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
    resolve: {
      companySettings: CompanySettingsResolver,
      purchase: PurchaseResolver,
      // locations: LocationsResolver,
      relatedUsersResolver: RelatedUsersResolver,
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
      },
      {
        path: 'accounts/:accountId',
        component: AccountDetailsLayoutComponent,
        children: [
          {
            path: 'details',
            loadChildren: () => import('./accounts/account-details/account-details.module').then(m => m.AccountDetailsModule),
          },
          {
            path: 'contacts',
            loadComponent: () => import('./accounts/account-contacts/account-contacts.component').then(m => m.AccountContactsComponent),
          },
          {
            path: 'tasks',
            loadChildren: () => import('./accounts/account-tasks/account-tasks.module').then(m => m.AccountTasksModule),
          },
          {
            path: 'shared',
            loadChildren: () => import('./accounts/shared-accounts/shared-accounts.module').then(m => m.SharedAccountsModule),
          },
          {
            path: 'account-activity-logs',
            loadChildren: () => import('./accounts/account-activity-logs/account-activity-logs.module').then(m => m.AccountActivityLogsModule),
          },
          {
            path: 'daily-routes',
            loadChildren: () => import('./accounts/account-daily-routes/account-daily-routes.module').then(m => m.AccountDailyRoutesModule),
          },
          {
            path: 'children',
            loadComponent: () => import('./accounts/account-children/account-children.component').then(m => m.AccountChildrenComponent),
          },
          {
            path: 'documents',
            loadComponent: () => import('./accounts/account-documents/account-documents.component').then(c => c.AccountDocumentsComponent),
          },
          {
            path: 'deals',
            loadComponent: () => import('./accounts/account-deals/account-deals.component').then(c => c.AccountDealsComponent),
          },
          {
            path: 'equipment',
            loadComponent: () => import('./accounts/account-equipment/account-equipment.component').then(c => c.AccountEquipmentComponent),
          },
        ]
      },
      {
        path: 'accounts',
        loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule),
      },
      {
        path: 'location',
        loadChildren: () => import('./location/location.module').then(m => m.LocationModule),
      },
      {
        path: 'documents',
        loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule),
      },
      {
        path: 'messages',
        loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'deals',
        loadComponent: () => import('./deals/deals-page/deals-page.component').then(m => m.DealsPageComponent),
      },
      {
        path: 'deals/settings',
        canMatch: [ PermissionsGuard ],
        data: {
          permissions: {
            redirectTo: 'dashboard',
            only: ['OWNER'],
            message: 'You do not have the permissions to access the deals settings',
          },
        },
        loadComponent: () => import('./deals/deal-settings/deal-settings.component').then(m => m.DealSettingsComponent),
      },
      {
        path: 'deals/:id',
        component: DealDetailsLayoutComponent,
        children: [
          {
            path: 'details',
            loadComponent: () => import('./deals/deal-details/deal-details.component').then(m => m.DealDetailsComponent),
          },
          {
            path: 'tasks',
            loadComponent: () => import('./deals/deal-details/deal-tasks/deal-tasks.component').then(m => m.DealTasksComponent),
          },
        ],
      },
      {
        path: 'equipment',
        loadComponent: () => import('./equipment/equipment-page.component').then(m => m.EquipmentPageComponent),
      },
      {
        path: 'equipment/:id',
        component: EquipmentDetailsLayoutComponent,
        children: [
          {
            path: 'details',
            loadComponent: () => import('./equipment/equipment-details/equipment-details.component').then(m => m.EquipmentDetailsComponent),
          },
          {
            path: 'wo',
            loadComponent: () => import('./equipment/equipment-details/external-wo-list/external-wo-list.component').then(m => m.ExternalWoListComponent),
          },
        ],
      },
    ]
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}
