import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectCurrentUserCompanyId } from '../../../current-user/store/current-user.selectors';
import { inputIsNotNullOrUndefined } from '../../../data-access/input-is-not-null-or-undefined';
import { doc, Firestore, updateDoc } from '@angular/fire/firestore';
import { omit } from "lodash";

export interface UpdateTagPayload {
  id: string,
  name: string,
}

@Injectable({
  providedIn: 'root',
})
export class UpdateTagService {
  constructor(private firestore: Firestore, private store$: Store) {
  }

  update(payload: UpdateTagPayload) {
    const document = omit(payload, [ 'id' ]);
    return this.store$.pipe(
      select(selectCurrentUserCompanyId),
      filter(inputIsNotNullOrUndefined),
      switchMap(companyId =>
        updateDoc(doc(this.firestore, `companyTags/${ companyId }/tags/${ payload.id }`), document),
      ),
      take(1),
    );
  }
}
