import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { decodeZodData } from "../../../models/handle-schema";
import { ExternalEquipmentSchema, externalEquipmentSchema } from '../../../models/external-equipment';

@Injectable({
  providedIn: 'root',
})
export class GetExternalEquipmentService {
  constructor(private http: HttpClient) {
  }

  get(id: string): Observable<ExternalEquipmentSchema> {
    return this.http.get<unknown>(`${ environment.apiV2.equipmentURL }/external/${ id }`).pipe(
      decodeZodData(externalEquipmentSchema),
    );
  }
}
